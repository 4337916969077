import {Action, Alert, AlertSeverity, Box, Button, ButtonLink, ButtonSize, ButtonStyle, ElementList, ElementListSize, FieldBlock, FlexContentDirection, FlexContentSpacing, Form, FormLayoutButtons, FormLayoutColumns, FormLayoutRows, FormValidationType, InputDate, InputDateType, ModalActions, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalHeader, ModalHeaderTitle, ModalNew, RadioButtons, TranslationLibFile} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Notification} from "../../../api/model/notifications/Notification";
import {NotificationDispatch} from "../../../api/model/notifications/NotificationDispatch";
import {NotificationDispatchField} from "../../../api/model/notifications/NotificationDispatchField";
import {NotificationField} from "../../../api/model/notifications/NotificationField";
import {User} from "../../../api/model/user/User";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {Formatter} from "../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {SearchItems, TagUserRow} from "../../snippet";

type ModalCreateUserDispatchesProps = {
    active: boolean;
    notification: Notification;
    onClose: (refresh?: boolean) => void;
};

enum ModalCreateUserDispatchesRecipientType {
    ALL = "ALL",
    LIST = "LIST"
}

type ModalCreateUserDispatchesForm = {
    recipientType: ModalCreateUserDispatchesRecipientType;
    dispatchDate?: string;
};

enum ModalCreateUserDispatchesFormField {
    DISPATCH_DATE = "dispatchDate",
    RECIPIENT_TYPE = "recipientType"
}

const ModalCreateUserDispatches: FunctionComponent<ModalCreateUserDispatchesProps> = ({active, notification, onClose}) => {
    const alert = useAlert();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingDispatch, setLoadingDispatch] = useState(false);
    const [dispatchForm, setDispatchForm] = useState<ModalCreateUserDispatchesForm>({[ModalCreateUserDispatchesFormField.RECIPIENT_TYPE]: ModalCreateUserDispatchesRecipientType.LIST});
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [highlightedUsers, setHighlightedUsers] = useState<User[]>([]);
    const [isShowModalConfirmDispatch, setShowModalConfirmDispatch] = useState(false);
    const FORM_ID = "form-create-user-dispatches";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<ModalCreateUserDispatchesRecipientType>();

    useEffect(() => {
        setDispatchForm({[ModalCreateUserDispatchesFormField.RECIPIENT_TYPE]: ModalCreateUserDispatchesRecipientType.LIST});
        setSelectedUsers([]);
        setShowErrors(false);
        if (active) {
            (async () => {
                try {
                    setLoading(true);
                    const users = await session.getActiveUsers();
                    setUsers(users);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("users", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [active, alert, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [ModalCreateUserDispatchesRecipientType.LIST]: dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.LIST && !selectedUsers.length
        }));
    }, [setErrors, dispatchForm.recipientType, selectedUsers]);

    const handleChange = (field: ModalCreateUserDispatchesFormField, value: any) => {
        setDispatchForm((prevState) => ({...prevState, [field]: value}));
    };

    const handleAddUsers = (users: User[]) => {
        setSelectedUsers((prevState) => [...prevState, ...users]);
        setHighlightedUsers(users);
        setTimeout(() => setHighlightedUsers([]), 1000);
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleActiveDispatch = (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        setShowModalConfirmDispatch(true);
    };

    const handleSubmit = async () => {
        try {
            setLoadingDispatch(true);
            const userIds = dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.LIST ? selectedUsers.map((it) => it.id) : [];
            const notificationDispatch = new NotificationDispatch({
                [NotificationDispatchField.IDS]: userIds,
                [NotificationDispatchField.DISPATCH_DATE]: Formatter.convertDateToUTC(dispatchForm.dispatchDate)
            });

            if (dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.ALL) {
                await session.restNotification.update(new Notification({
                    ...notification,
                    [NotificationField.EXPIRES_AT]: notification.expires_at ? Formatter.convertDateToUTC(notification.expires_at) : undefined,
                    [NotificationField.IS_BROADCAST]: true
                }));
                await session.restNotification.dispatch(notification.id, notificationDispatch);
            } else {
                await session.restNotification.addUsers(notification.id, notificationDispatch);
            }
            alert.createWithSuccess("dispatches");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("dispatches", e.message);
            }
        } finally {
            setLoadingDispatch(false);
            setShowModalConfirmDispatch(false);
        }
    };

    return (
        <>
            <ModalNew onClose={() => onClose()} active={active}>
                <ModalHeader>
                    <ModalHeaderTitle title={textNotifications("create_dispatches_to_users")}/>
                </ModalHeader>
                <ModalContent>
                    <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleActiveDispatch} validationType={FormValidationType.CUSTOM}>
                        <FormLayoutRows>
                            <Alert text={textNotifications("message.recipients_mention")} severity={AlertSeverity.WARNING}/>
                            <FormLayoutColumns columns={3}>
                                <FieldBlock
                                    label={textNotifications("field.dispatch_date")}
                                    tooltip={textNotifications("tooltip.dispatch_date")}
                                    required
                                >
                                    <InputDate
                                        type={InputDateType.DATETIME_LOCAL}
                                        value={dispatchForm.dispatchDate}
                                        onChange={(value) => handleChange(ModalCreateUserDispatchesFormField.DISPATCH_DATE, value)}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                            <FieldBlock label={textNotifications("field.recipients")}>
                                <RadioButtons
                                    id={ModalCreateUserDispatchesFormField.RECIPIENT_TYPE}
                                    value={dispatchForm.recipientType}
                                    options={[
                                        {value: ModalCreateUserDispatchesRecipientType.LIST, label: textNotifications(`users.${ModalCreateUserDispatchesRecipientType.LIST}`)},
                                        {value: ModalCreateUserDispatchesRecipientType.ALL, label: textNotifications(`users.${ModalCreateUserDispatchesRecipientType.ALL}`)}
                                    ]}
                                    onChange={(value) => handleChange(ModalCreateUserDispatchesFormField.RECIPIENT_TYPE, value)}
                                />
                            </FieldBlock>
                            {dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.LIST &&
                                <Box>
                                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                                            <SearchItems
                                                items={users}
                                                selectedItems={selectedUsers}
                                                searchField="fullName"
                                                onSubmit={handleAddUsers}
                                                loading={isLoading}
                                            />
                                            {formValidator.isError(ModalCreateUserDispatchesRecipientType.LIST) &&
                                                <FormLayoutMessage message={t("message.error.element_required", {element: "user"})} severity={AlertSeverity.DANGER} small/>
                                            }
                                        </FieldBlock>
                                        <FieldBlock
                                            label={textNotifications("users.selected", {count: selectedUsers.length})}
                                            actions={!!selectedUsers.length &&
                                                <ButtonLink onClick={() => setSelectedUsers([])}>
                                                    {textCommon(Action.REMOVE_ALL.labelKey)}
                                                </ButtonLink>
                                            }
                                        >
                                            <ElementList placeholder={textNotifications("users.no_selected")} size={ElementListSize.BIG}>
                                                {selectedUsers.map((user) =>
                                                    <TagUserRow
                                                        key={user.id}
                                                        user={user}
                                                        isHighlighted={highlightedUsers.some(({id}) => user.id === id)}
                                                        onRemove={() => setSelectedUsers(selectedUsers.filter(({id}) => user.id !== id))}
                                                    />
                                                )}
                                            </ElementList>
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            }
                        </FormLayoutRows>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <Button form={FORM_ID} size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} loading={isLoadingDispatch}>
                        {textCommon(Action.SEND.labelKey)}
                    </Button>
                </ModalActions>
            </ModalNew>
            <ModalNew active={isShowModalConfirmDispatch}>
                <ModalContent>
                    <FormLayoutRows>
                        {dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.ALL ?
                            <Alert
                                severity={AlertSeverity.WARNING}
                                text={textNotifications("message.create_summary_all", {
                                    dispatchDate: Formatter.formatDate(dispatchForm.dispatchDate, Formatter.DATETIME_FORMAT)
                                })}
                            /> :
                            <Alert
                                severity={AlertSeverity.WARNING}
                                text={textNotifications("message.create_summary_specific", {
                                    dispatchDate: Formatter.formatDate(dispatchForm.dispatchDate, Formatter.DATETIME_FORMAT),
                                    count: selectedUsers?.length
                                })}
                            />
                        }
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textNotifications("message.create_summary_confirm")}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                onClick={() => setShowModalConfirmDispatch(false)}
                            >
                                {textCommon(Action.CANCEL.labelKey)}
                            </Button>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.DEFAULT_MIDNIGHT}
                                onClick={handleSubmit}
                            >
                                {textNotifications("actions.create_dispatch")}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </>
    );
};

export default ModalCreateUserDispatches;
