import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionItem,
    AccordionItemContent,
    Action,
    Alert,
    AlertSeverity,
    Button,
    ButtonLinkCancel,
    ButtonStyle,
    ButtonValidate,
    Form,
    FormLayoutButtons,
    FormLayoutRows,
    FormValidationType,
    Loader,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Paragraph,
    ParagraphAlignment,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import useAlert from "../../../utils/hooks/useAlert";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {CmpSirdataListPurposesNewVersion, CmpSirdataListStacksNewVersion, CmpSirdataListVendorsNewVersion} from "../../snippet";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersion";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list/SirdataListLanguage";
import {SirdataListVendor} from "../../../api/model/cmp/list/sirdata-list/SirdataListVendor";
import {CmpConfigLanguage} from "../../../api/model/cmp/config/CmpConfigLanguage";
import {detectChanges} from "../../../common/utils/portal";
import {usePreprod} from "../../../common/utils/helper";

type ModalCreateCmpSirdataListVersionProps = {
    latestVersion: SirdataListVersion;
    listLanguages: SirdataListLanguage[];
    listVendors: SirdataListVendor[];
    active: boolean;
    onClose: (refresh?: boolean) => void;
};

enum ModalCreateCmpSirdataListVersionStep {
    VERIFYING,
    NO_DATA,
    DATA_VERIFIED
}

const ModalCreateCmpSirdataListVersion: FunctionComponent<ModalCreateCmpSirdataListVersionProps> = ({latestVersion, listLanguages, listVendors, active, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [isSubmitting, setSubmitting] = useState(false);
    const [step, setStep] = useState<ModalCreateCmpSirdataListVersionStep>(ModalCreateCmpSirdataListVersionStep.VERIFYING);
    const [defaultListLanguage, setDefaultListLanguage] = useState<SirdataListLanguage>(new SirdataListLanguage());
    const [errors, setErrors] = useState<string[]>([]);
    const FORM_ID = "form-create-cmp-sirdata-list-version";

    useEffect(() => {
        setStep(ModalCreateCmpSirdataListVersionStep.VERIFYING);

        if (active) {
            setErrors([]);
            setTimeout(() => {
                const hasChanges = (
                    detectChanges(latestVersion.vendors, listVendors) ||
                    detectChanges(latestVersion.stacks, defaultListLanguage.stacks) ||
                    detectChanges(latestVersion.purposes, defaultListLanguage.purposes)
                );
                if (!hasChanges) {
                    setStep(ModalCreateCmpSirdataListVersionStep.NO_DATA);
                    return;
                }

                const errors: string[] = [];
                listVendors.filter((it) => !it.isValid()).forEach((vendor) => {
                    errors.push(textCmpSirdataList("message.invalid_vendor", {name: vendor.name, id: vendor.id}));
                });
                listLanguages.forEach((language) => {
                    if (!language.isValid() || (language.language !== defaultListLanguage.language && !language.isAlike(defaultListLanguage))) {
                        errors.push(textCmpSirdataList("message.invalid_language", {language: textCmpConfigurations(`language.${language.language}`)}));
                    }
                });

                setErrors(errors);
                setStep(ModalCreateCmpSirdataListVersionStep.DATA_VERIFIED);
            }, 500);
        }
    }, [active, latestVersion, listLanguages, listVendors, textCmpSirdataList, textCmpConfigurations, defaultListLanguage]);

    useEffect(() => {
        setDefaultListLanguage(listLanguages.find((it) => it.language === CmpConfigLanguage.ENGLISH.name) as SirdataListLanguage);
    }, [listLanguages]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await session.restCmpSirdataListVersion.create();
            alert.createWithSuccess("version");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("version", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpSirdataList("publish_version")}/>
            </ModalHeader>
            {step === ModalCreateCmpSirdataListVersionStep.VERIFYING &&
                <ModalContent>
                    <FormLayoutRows>
                        <Loader/>
                        <Paragraph alignment={ParagraphAlignment.CENTER}>
                            {textCmpSirdataList("message.data_verifying")}
                        </Paragraph>
                    </FormLayoutRows>
                </ModalContent>
            }
            {step === ModalCreateCmpSirdataListVersionStep.NO_DATA &&
                <ModalContent>
                    <FormLayoutRows>
                        <Alert
                            text={textCmpSirdataList("message.no_changes_to_publish")}
                            severity={AlertSeverity.INFO}
                        />
                        <FormLayoutButtons>
                            <Button style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => onClose(false)}>
                                {textCommon(Action.CANCEL.labelKey)}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            }
            {step === ModalCreateCmpSirdataListVersionStep.DATA_VERIFIED &&
                <>
                    <ModalContent>
                        <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                            <FormLayoutRows>
                                {!!errors.length &&
                                    <Accordion>
                                        <AccordionItem heading={<Alert text={textCmpSirdataList("message.invalid_data")} severity={AlertSeverity.DANGER} fullWidth/>}>
                                            <AccordionItemContent>
                                                {errors.map((error) => <p key={error} dangerouslySetInnerHTML={{__html: error}}/>)}
                                            </AccordionItemContent>
                                        </AccordionItem>
                                    </Accordion>
                                }
                                <CmpSirdataListVendorsNewVersion
                                    latestVersion={latestVersion}
                                    listVendors={listVendors}
                                />
                                <CmpSirdataListStacksNewVersion
                                    latestVersion={latestVersion}
                                    listLanguage={defaultListLanguage}
                                />
                                <CmpSirdataListPurposesNewVersion
                                    latestVersion={latestVersion}
                                    listLanguage={defaultListLanguage}
                                />
                            </FormLayoutRows>
                        </Form>
                    </ModalContent>
                    <ModalActions>
                        <ButtonLinkCancel onClick={() => onClose(false)}/>
                        {(!errors.length || usePreprod) &&
                            <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
                        }
                    </ModalActions>
                </>
            }
        </ModalNew>
    );
};

export default ModalCreateCmpSirdataListVersion;
