import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {User} from "../../../api/model/user/User";
import {RevenueSeat} from "../../../api/model/revenue/seat/RevenueSeat";
import {RevenueSeatField} from "../../../api/model/revenue/seat/RevenueSeatField";
import {session} from "../../../api/ApiSession";
import {Alert, AlertSeverity, Button, ButtonSize, ButtonStyle, Form, FormLayoutButtons, FormLayoutRows, FormValidationType, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalNew} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalAssignSeatsProps = {
    active: boolean;
    initOwnerId?: number;
    newOwnerId: number;
    seats: RevenueSeat[];
    onUpdate: () => void;
    onClose: () => void;
};

const ModalAssignSeats: FunctionComponent<ModalAssignSeatsProps> = ({active, initOwnerId, newOwnerId, seats, onUpdate, onClose}) => {
    const alert = useAlert();
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const [isLoadingUpdate, setLoadingUpdate] = useState(false);
    const [isShowModalAssignOwner, setShowModalAssignOwner] = useState(false);
    const [newOwner, setNewOwner] = useState<User>();
    const FORM_ID = "form-assign-seats";

    const handleAssignAll = async () => {
        if (!seats.length || !newOwner) {
            return;
        }
        setLoadingUpdate(true);
        try {
            for (let i = 0; i < seats.length; i++) {
                const seat = seats[i];
                if (seat.owner_id !== newOwnerId) {
                    await session.restSeat.update(new RevenueSeat({...seat, [RevenueSeatField.OWNER_ID]: newOwner.id}));
                }
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("seats", e.message);
            }
        } finally {
            handleSubmit();
        }
    };

    const handleAssignPreviousOwnerSeats = async () => {
        if (!seats.length || !newOwner) {
            return;
        }
        setLoadingUpdate(true);
        try {
            for (let i = 0; i < seats.length; i++) {
                const seat = seats[i];
                if (seat.owner_id === initOwnerId) {
                    await session.restSeat.update(new RevenueSeat({...seat, [RevenueSeatField.OWNER_ID]: newOwner.id}));
                }
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("seats", e.message);
            }
        } finally {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        alert.updateWithSuccess("seats");
        setLoadingUpdate(false);
        setShowModalAssignOwner(false);
        onUpdate();
    };

    useEffect(() => {
        if (!newOwnerId) return;
        (async () => {
            try {
                setNewOwner(await session.getUserById(newOwnerId));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("owner", e.message);
                }
            }
        })();
    }, [newOwnerId, alert]);

    return (
        <>
            <ModalNew active={active}>
                <ModalContent>
                    <Form id={FORM_ID} validationType={FormValidationType.CUSTOM}>
                        <FormLayoutRows>
                            <Alert
                                severity={AlertSeverity.WARNING}
                                text={textOrganizations("modal.change_owner.seats_with_another_owner", {count: seats?.length, user: newOwner?.fullName})}
                            />
                            <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                                <span dangerouslySetInnerHTML={{__html: textOrganizations("modal.change_owner.assign_to_new_owner", {user: newOwner?.fullName})}}/>
                            </ModalDescription>
                            <FormLayoutButtons>
                                <Button
                                    size={ButtonSize.MEDIUM}
                                    style={ButtonStyle.DEFAULT_MIDNIGHT}
                                    onClick={onClose}
                                >
                                    {textOrganizations("modal.change_owner.keep_current_owner")}
                                </Button>
                                <Button
                                    size={ButtonSize.MEDIUM}
                                    style={ButtonStyle.PRIMARY_MIDNIGHT}
                                    onClick={() => setShowModalAssignOwner(true)}
                                    loading={isLoadingUpdate}
                                >
                                    {textOrganizations("modal.change_owner.assign_new_owner")}
                                </Button>
                            </FormLayoutButtons>
                        </FormLayoutRows>
                    </Form>
                </ModalContent>
            </ModalNew>
            <ModalNew active={isShowModalAssignOwner}>
                <ModalContent>
                    <FormLayoutRows>
                        <Alert
                            severity={AlertSeverity.WARNING}
                            text={textOrganizations("modal.change_owner.seats_with_another_owner", {count: seats?.length})}
                        />
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textOrganizations("modal.change_owner.assign_previous_or_all_to_new_owner")}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button
                                size={ButtonSize.MEDIUM}
                                style={ButtonStyle.DEFAULT_MIDNIGHT}
                                onClick={handleAssignPreviousOwnerSeats}
                                loading={isLoadingUpdate}
                            >
                                {textOrganizations("modal.change_owner.assign_previous_owner_seats")}
                            </Button>
                            <Button
                                size={ButtonSize.MEDIUM}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                onClick={handleAssignAll}
                                loading={isLoadingUpdate}
                            >
                                {textOrganizations("modal.change_owner.assign_all")}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </>
    );
};

export default ModalAssignSeats;
