import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CmpConfigLanguage} from "../../config/CmpConfigLanguage";
import {SirdataListVendor} from "./SirdataListVendor";
import {SirdataListPurpose} from "./SirdataListPurpose";
import {SirdataListStack} from "./SirdataListStack";
import {SirdataListVersionField} from "./SirdataListVersionField";
import {ContentElement} from "../../../../interface/ContentElement";
import {usePreprod} from "../../../../../common/utils/helper";

export class SirdataListVersion extends ApiModel {
    version: number = 0;
    language: string = CmpConfigLanguage.ENGLISH.name;
    tcfPolicyVersion: number = 0;
    lastUpdated: string = "";
    purposes: SirdataListPurpose[] = [];
    stacks: SirdataListStack[] = [];
    vendors: SirdataListVendor[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) {
            return;
        }
        super.load(o);
        this.loadObjects(SirdataListVersionField.PURPOSES, SirdataListPurpose);
        this.loadObjects(SirdataListVersionField.STACKS, SirdataListStack);
        this.loadObjects(SirdataListVersionField.VENDORS, SirdataListVendor);
    }

    getPurposesJson(): { [key: string]: object } | undefined {
        if (!this.purposes) {
            return undefined;
        }
        let purposesJson: { [key: string]: object } = {};
        this.purposes.forEach((purpose) => {
            purposesJson[purpose.id] = purpose.getJsonParameters();
        });

        return purposesJson;
    }

    getStacksJson(): { [key: string]: object } | undefined {
        if (!this.stacks) {
            return undefined;
        }
        let stacksJson: { [key: string]: object } = {};
        this.stacks.forEach((stack) => {
            stacksJson[stack.id] = stack.getJsonParameters();
        });

        return stacksJson;
    }

    getVendorsJson(): { [key: string]: object } | undefined {
        if (!this.vendors) {
            return undefined;
        }
        let vendorsJson: { [key: string]: object } = {};
        this.vendors.forEach((vendor) => {
            vendorsJson[vendor.id] = vendor.getJsonParameters();
        });

        return vendorsJson;
    }

    getJsonParameters(): {} {
        return {
            version: this.version,
            language: this.language,
            tcfPolicyVersion: this.tcfPolicyVersion,
            lastUpdated: this.lastUpdated,
            purposes: this.getPurposesJson(),
            stacks: this.getStacksJson(),
            vendors: this.getVendorsJson()
        };
    }

    toContentElement(): ContentElement {
        const element = new ContentElement("", `Version ${this.version}`);
        element.lastUpdated = new Date(this.lastUpdated);

        const urlRoot = usePreprod ? "https://api-preprod.consentframework.com" : "https://api.consentframework.com";
        element.url = `${urlRoot}/api/v1/public/list/sirdata`;

        return element;
    }
}
