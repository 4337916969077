import React, {FunctionComponent, Suspense, useEffect, useState} from "react";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {ScrollToTop} from "@sirdata/ui-lib";
import {session} from "./api/ApiSession";
import {Authorization} from "./api/model/account/Authorization";
import {Error403, Error404, Error500} from "./common/screen";
import {pathError403, pathError404, pathError500, pathHome, pathLogin} from "./common/utils/constants";
import PrivateRoute from "./component/guard/PrivateRoute";
import ModalModules from "./component/modal/ModalModules";
import ModalTicketing from "./component/modal/ModalTicketing";
import ModalCreateMedia from "./component/modal/medias/ModalCreateMedia";
import {
    AdsAccess,
    AdsAccessDetails,
    ApiDocumentations,
    AudienceDataSelections,
    AudienceDataSelectionsDetails,
    AuthorizationsPartner,
    AuthorizationsUser,
    Categories,
    CategoriesDetails,
    Categorizer,
    CategorizerAdvancedSearch,
    CategorizerDetails,
    CmpConfigurations,
    CmpConfigurationsDetails,
    CmpConsentManagementPlatforms,
    CmpGlobalVendorList,
    CmpGoogleACProviderList,
    CmpNetworks,
    CmpSirdataList,
    CmpTranslations,
    CmpVersions,
    CmpVersionsDetails,
    CmpWebinars,
    CmpWebinarsDetails,
    CustomerCountings,
    CustomerCountingsDetails,
    CustomerOrders,
    CustomerOrdersDetails,
    CustomerStorages,
    CustomerStoragesDetails,
    DataleaksAudits,
    DataleaksAuditsDetails,
    DataleaksAuditsReport,
    DataleaksQuestions,
    DataleaksQuestionsDetails,
    DataleaksStatistics,
    DataleaksVersions,
    DataleaksVersionsDetails,
    Deals,
    DealsDetails,
    Distributions,
    DistributionsDetails,
    DistributionsLicenses,
    HelperDomains,
    HelperDomainsDetails,
    Home,
    Keywords,
    Licenses,
    LicensesDetails,
    Login,
    Medias,
    MediasDetails,
    Notifications,
    NotificationsDetails,
    OrganizationGroups,
    OrganizationGroupsDetails,
    Organizations,
    OrganizationsDetails,
    PartnerDomains,
    Partners,
    PartnersConnect,
    PartnersDetails,
    PortalSettings,
    PortalSettingsDetails,
    Pricings,
    PricingsDetails,
    RevenuesImports,
    Seats,
    SeatsDetails,
    Segments,
    SegmentsDetails,
    SgtmContainers,
    SgtmContainersDetails,
    TaxonomyTags,
    TaxonomyTagsDetails,
    UserProfiles,
    UserProfilesDetails,
    Users,
    UsersDetails
} from "./screen";
import {pathCategorizerAdvancedSearch, pathDataleaksReport, pathPartnersConnect} from "./utils/constants";
import {Module} from "./utils/Module";
import {PortalContextProvider} from "./common/context/PortalContext";
import {PortalSetting} from "./common/api/model/portal-setting/PortalSetting";
import {ApiEventListener} from "./common/component/snippet";
import {SirdataApiEvent} from "./common/api/CommonApiClient";

const App: FunctionComponent = () => {
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());

    useEffect(() => {
        (async () => {
            try {
                setPortalSetting(await session.getPortalSetting());
            } catch (e) {
            }
        })();
    }, []);

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <PortalContextProvider portalSetting={portalSetting}>
                    <BrowserRouter>
                        <ApiEventListener/>
                        <ScrollToTop/>
                        <Routes>
                            <Route path={pathError403} element={<Error403/>}/>
                            <Route path={pathError404} element={<Error404/>}/>
                            <Route path={pathError500} element={<Error500/>}/>

                            <Route path="/" element={<Navigate to={pathLogin}/>}/>
                            <Route path={pathLogin} element={<Login/>}/>

                            <Route element={<PrivateLayout/>}>
                                <Route path={pathHome} element={<PrivateRoute children={<Home/>}/>}/>
                                <Route path={pathCategorizerAdvancedSearch} element={<PrivateRoute children={<CategorizerAdvancedSearch/>} requiredAuthorization={Authorization.CATEGORIZER_ADMIN.name}/>}/>
                                <Route path={pathDataleaksReport} element={<PrivateRoute children={<DataleaksAuditsReport/>} requiredAuthorization={Authorization.DATALEAKS.name}/>}/>
                                <Route path={pathPartnersConnect} element={<PrivateRoute children={<PartnersConnect/>} requiredAuthorization={Authorization.LOG_AS_PARTNER.name}/>}/>

                                <Route {...Module.ADS} element={<PrivateRoute children={<AdsAccess/>}/>}/>
                                <Route {...Module.ADS} path={Module.ADS.pathWithId} element={<PrivateRoute children={<AdsAccessDetails/>}/>}/>

                                <Route {...Module.API_DOCUMENTATIONS} element={<PrivateRoute children={<ApiDocumentations/>}/>}/>

                                <Route {...Module.AUDIENCE_DATA_SELECTIONS} element={<PrivateRoute children={<AudienceDataSelections/>}/>}/>
                                <Route {...Module.AUDIENCE_DATA_SELECTIONS} path={Module.AUDIENCE_DATA_SELECTIONS.pathWithId} element={<PrivateRoute children={<AudienceDataSelectionsDetails/>}/>}/>

                                <Route {...Module.AUTHORIZATIONS_PARTNER} element={<PrivateRoute children={<AuthorizationsPartner/>}/>}/>
                                <Route {...Module.AUTHORIZATIONS_USER} element={<PrivateRoute children={<AuthorizationsUser/>}/>}/>

                                <Route {...Module.CATEGORIES} element={<PrivateRoute children={<Categories/>}/>}/>
                                <Route {...Module.CATEGORIES} path={Module.CATEGORIES.pathWithId} element={<PrivateRoute children={<CategoriesDetails/>}/>}/>

                                <Route {...Module.CATEGORIZER} element={<PrivateRoute children={<Categorizer/>}/>}/>
                                <Route {...Module.CATEGORIZER} path={Module.CATEGORIZER.pathWithId} element={<PrivateRoute children={<CategorizerDetails/>}/>}/>

                                <Route {...Module.CMP_CONFIGURATIONS} element={<PrivateRoute children={<CmpConfigurations/>}/>}/>
                                <Route {...Module.CMP_CONFIGURATIONS} path={Module.CMP_CONFIGURATIONS.pathWithId} element={<PrivateRoute children={<CmpConfigurationsDetails/>}/>}/>

                                <Route {...Module.CMP_CONSENT_MANAGEMENT_PLATFORMS} element={<PrivateRoute children={<CmpConsentManagementPlatforms/>}/>}/>

                                <Route {...Module.CMP_GLOBAL_VENDOR_LIST} element={<PrivateRoute children={<CmpGlobalVendorList/>}/>}/>

                                <Route {...Module.CMP_GOOGLE_AC_PROVIDER_LIST} element={<PrivateRoute children={<CmpGoogleACProviderList/>}/>}/>

                                <Route {...Module.CMP_NETWORKS} element={<PrivateRoute children={<CmpNetworks/>}/>}/>

                                <Route {...Module.CMP_SIRDATA_LIST} element={<PrivateRoute children={<CmpSirdataList/>}/>}/>

                                <Route {...Module.CMP_TRANSLATIONS} element={<PrivateRoute children={<CmpTranslations/>}/>}/>

                                <Route {...Module.CMP_VERSIONS} element={<PrivateRoute children={<CmpVersions/>}/>}/>
                                <Route {...Module.CMP_VERSIONS} path={Module.CMP_VERSIONS.pathWithId} element={<PrivateRoute children={<CmpVersionsDetails/>}/>}/>

                                <Route {...Module.CMP_WEBINARS} element={<PrivateRoute children={<CmpWebinars/>}/>}/>
                                <Route {...Module.CMP_WEBINARS} path={Module.CMP_WEBINARS.pathWithId} element={<PrivateRoute children={<CmpWebinarsDetails/>}/>}/>

                                <Route {...Module.CUSTOMER_COUNTINGS} element={<PrivateRoute children={<CustomerCountings/>}/>}/>
                                <Route {...Module.CUSTOMER_COUNTINGS} path={Module.CUSTOMER_COUNTINGS.pathWithId} element={<PrivateRoute children={<CustomerCountingsDetails/>}/>}/>

                                <Route {...Module.CUSTOMER_ORDERS} element={<PrivateRoute children={<CustomerOrders/>}/>}/>
                                <Route {...Module.CUSTOMER_ORDERS} path={Module.CUSTOMER_ORDERS.pathWithId} element={<PrivateRoute children={<CustomerOrdersDetails/>}/>}/>

                                <Route {...Module.CUSTOMER_STORAGES} element={<PrivateRoute children={<CustomerStorages/>}/>}/>
                                <Route {...Module.CUSTOMER_STORAGES} path={Module.CUSTOMER_STORAGES.pathWithId} element={<PrivateRoute children={<CustomerStoragesDetails/>}/>}/>

                                <Route {...Module.DATALEAKS_QUESTIONS} element={<PrivateRoute children={<DataleaksQuestions/>}/>}/>
                                <Route {...Module.DATALEAKS_QUESTIONS} path={Module.DATALEAKS_QUESTIONS.pathWithId} element={<PrivateRoute children={<DataleaksQuestionsDetails/>}/>}/>

                                <Route {...Module.DATALEAKS_STATISTICS} element={<PrivateRoute children={<DataleaksStatistics/>}/>}/>

                                <Route {...Module.DATALEAKS_VERSIONS} element={<PrivateRoute children={<DataleaksVersions/>}/>}/>
                                <Route {...Module.DATALEAKS_VERSIONS} path={Module.DATALEAKS_VERSIONS.pathWithId} element={<PrivateRoute children={<DataleaksVersionsDetails/>}/>}/>

                                <Route {...Module.DATALEAKS} element={<PrivateRoute children={<DataleaksAudits/>}/>}/>
                                <Route {...Module.DATALEAKS} path={Module.DATALEAKS.pathWithId} element={<PrivateRoute children={<DataleaksAuditsDetails/>}/>}/>

                                <Route {...Module.DEALS} element={<PrivateRoute children={<Deals/>}/>}/>
                                <Route {...Module.DEALS} path={Module.DEALS.pathWithId} element={<PrivateRoute children={<DealsDetails/>}/>}/>

                                <Route {...Module.DISTRIBUTIONS} element={<PrivateRoute children={<Distributions/>}/>}/>
                                <Route {...Module.DISTRIBUTIONS} path={Module.DISTRIBUTIONS.pathWithId} element={<PrivateRoute children={<DistributionsDetails/>}/>}/>

                                <Route {...Module.DISTRIBUTIONS_LICENSES} element={<PrivateRoute children={<DistributionsLicenses/>}/>}/>

                                <Route {...Module.HELPER_DOMAINS} element={<PrivateRoute children={<HelperDomains/>}/>}/>
                                <Route {...Module.HELPER_DOMAINS} path={Module.HELPER_DOMAINS.pathWithId} element={<PrivateRoute children={<HelperDomainsDetails/>}/>}/>

                                <Route {...Module.KEYWORDS} element={<PrivateRoute children={<Keywords/>}/>}/>

                                <Route {...Module.LICENSES} element={<PrivateRoute children={<Licenses/>}/>}/>
                                <Route {...Module.LICENSES} path={Module.LICENSES.pathWithId} element={<PrivateRoute children={<LicensesDetails/>}/>}/>

                                <Route {...Module.MEDIAS} element={<PrivateRoute children={<Medias/>}/>}/>
                                <Route {...Module.MEDIAS} path={Module.MEDIAS.pathWithId} element={<PrivateRoute children={<MediasDetails/>}/>}/>

                                <Route {...Module.NOTIFICATIONS} element={<PrivateRoute children={<Notifications/>}/>}/>
                                <Route {...Module.NOTIFICATIONS} path={Module.NOTIFICATIONS.pathWithId} element={<PrivateRoute children={<NotificationsDetails/>}/>}/>

                                <Route {...Module.ORGANIZATION_GROUPS} element={<PrivateRoute children={<OrganizationGroups/>}/>}/>
                                <Route {...Module.ORGANIZATION_GROUPS} path={Module.ORGANIZATION_GROUPS.pathWithId} element={<PrivateRoute children={<OrganizationGroupsDetails/>}/>}/>

                                <Route {...Module.ORGANIZATIONS} element={<PrivateRoute children={<Organizations/>}/>}/>
                                <Route {...Module.ORGANIZATIONS} path={Module.ORGANIZATIONS.pathWithId} element={<PrivateRoute children={<OrganizationsDetails/>}/>}/>

                                <Route {...Module.PARTNER_DOMAINS} element={<PrivateRoute children={<PartnerDomains/>}/>}/>

                                <Route {...Module.PARTNERS} element={<PrivateRoute children={<Partners/>}/>}/>
                                <Route {...Module.PARTNERS} path={Module.PARTNERS.pathWithId} element={<PrivateRoute children={<PartnersDetails/>}/>}/>

                                <Route {...Module.PORTAL_SETTINGS} element={<PrivateRoute children={<PortalSettings/>}/>}/>
                                <Route {...Module.PORTAL_SETTINGS} path={Module.PORTAL_SETTINGS.pathWithId} element={<PrivateRoute children={<PortalSettingsDetails/>}/>}/>

                                <Route {...Module.PRICINGS} element={<PrivateRoute children={<Pricings/>}/>}/>
                                <Route {...Module.PRICINGS} path={Module.PRICINGS.pathWithId} element={<PrivateRoute children={<PricingsDetails/>}/>}/>

                                <Route {...Module.REVENUES_IMPORTS} element={<PrivateRoute children={<RevenuesImports/>}/>}/>

                                <Route {...Module.SEATS} element={<PrivateRoute children={<Seats/>}/>}/>
                                <Route {...Module.SEATS} path={Module.SEATS.pathWithId} element={<PrivateRoute children={<SeatsDetails/>}/>}/>

                                <Route {...Module.SEGMENTS} element={<PrivateRoute children={<Segments/>}/>}/>
                                <Route {...Module.SEGMENTS} path={Module.SEGMENTS.pathWithId} element={<PrivateRoute children={<SegmentsDetails/>}/>}/>

                                <Route {...Module.SGTM_CONTAINERS} element={<PrivateRoute children={<SgtmContainers/>}/>}/>
                                <Route {...Module.SGTM_CONTAINERS} path={Module.SGTM_CONTAINERS.pathWithId} element={<PrivateRoute children={<SgtmContainersDetails/>}/>}/>

                                <Route {...Module.TAXONOMY_TAGS} element={<PrivateRoute children={<TaxonomyTags/>}/>}/>
                                <Route {...Module.TAXONOMY_TAGS} path={Module.TAXONOMY_TAGS.pathWithId} element={<PrivateRoute children={<TaxonomyTagsDetails/>}/>}/>

                                <Route {...Module.USER_PROFILES} element={<PrivateRoute children={<UserProfiles/>}/>}/>
                                <Route {...Module.USER_PROFILES} path={Module.USER_PROFILES.pathWithId} element={<PrivateRoute children={<UserProfilesDetails/>}/>}/>

                                <Route {...Module.USERS} element={<PrivateRoute children={<Users/>}/>}/>
                                <Route {...Module.USERS} path={Module.USERS.pathWithId} element={<PrivateRoute children={<UsersDetails/>}/>}/>
                            </Route>

                            <Route path="*" element={<Error404/>}/>
                        </Routes>
                        <ModalModules/>
                        <ModalTicketing/>
                        <ModalCreateMedia/>
                    </BrowserRouter>
                </PortalContextProvider>
            </Suspense>
        </React.StrictMode>
    );
};

export default App;

function PrivateLayout() {
    useEffect(() => {
        session.on(SirdataApiEvent.eventUnauthorizedRequest, () => session.clean());
    }, []);

    return (
        <Outlet/>
    );
}
