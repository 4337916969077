import clsx from "clsx";
import {LoggedAccount} from "../../../common/api/interface/LoggedAccount";
import {ApiModel} from "../../../common/api/model/ApiModel";

export class UserAccount extends ApiModel implements LoggedAccount {
    _id: number = 0;
    email: string = "";
    first_name: string = "";
    last_name: string = "";
    authorizations: string[] = [];
    is_admin: boolean = false;
    created_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get fullName(): string {
        if (this.first_name || this.last_name) {
            return clsx(this.first_name, this.last_name);
        }
        return "";
    }

    get initials(): string {
        return this.first_name.substring(0, 1) + this.last_name.substring(0, 1);
    }

    get createdAt(): string {
        return this.created_at;
    }

    isUser(): boolean {
        return true;
    }

    hasAccess(origin?: string): boolean {
        return true;
    }

    hasAuthorization(authorization: string): boolean {
        if (!authorization) return true;
        if (this.is_admin) return true;
        return !!this.authorizations.find((it) => it === authorization);
    }

    hasAnyOfAuthorizations(authorizations?: string[]): boolean {
        if (!authorizations || !authorizations.length) return true;
        if (this.is_admin) return true;
        return !!this.authorizations.find((it) => authorizations.includes(it));
    }
}
