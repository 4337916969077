import {Action, Alert, AlertSeverity, Box, Button, ButtonLink, ButtonSize, ButtonStyle, ElementList, ElementListSize, FieldBlock, FlexContentDirection, FlexContentSpacing, Form, FormLayoutButtons, FormLayoutColumns, FormLayoutRows, FormValidationType, InputDate, InputDateType, ModalActions, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalHeader, ModalHeaderTitle, ModalNew, RadioButtons, Select, TranslationLibFile} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Notification} from "../../../api/model/notifications/Notification";
import {NotificationDispatch} from "../../../api/model/notifications/NotificationDispatch";
import {NotificationDispatchField} from "../../../api/model/notifications/NotificationDispatchField";
import {NotificationField} from "../../../api/model/notifications/NotificationField";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {PartnerOrganizationField} from "../../../api/model/partner/organization/PartnerOrganizationField";
import {PartnerOrganizationType} from "../../../api/model/partner/organization/PartnerOrganizationType";
import {Partner} from "../../../api/model/partner/Partner";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {Formatter} from "../../../common/utils/Formatter";
import {sortItems} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {SearchItems, TagOrganizationRow, TagPartnerRow} from "../../snippet";

type ModalCreatePartnerDispatchesProps = {
    active: boolean;
    notification: Notification;
    onClose: (refresh?: boolean) => void;
};

enum ModalCreatePartnerDispatchesRecipientType {
    ALL = "ALL",
    PARTNER_LIST = "PARTNER_LIST",
    ORGANIZATION_LIST = "ORGANIZATION_LIST"
}

type ModalCreatePartnerDispatchesForm = {
    recipientType: ModalCreatePartnerDispatchesRecipientType;
    dispatchDate?: string;
};

enum ModalCreatePartnerDispatchesFormField {
    DISPATCH_DATE = "dispatchDate",
    RECIPIENT_TYPE = "recipientType"
}

const ModalCreatePartnerDispatches: FunctionComponent<ModalCreatePartnerDispatchesProps> = ({active, notification, onClose}) => {
    const alert = useAlert();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingDispatch, setLoadingDispatch] = useState(false);
    const [dispatchForm, setDispatchForm] = useState<ModalCreatePartnerDispatchesForm>({[ModalCreatePartnerDispatchesFormField.RECIPIENT_TYPE]: ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST});
    const [partners, setPartners] = useState<Partner[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
    const [highlightedPartners, setHighlightedPartners] = useState<Partner[]>([]);
    const [organizations, setOrganizations] = useState<PartnerOrganization[]>([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState<PartnerOrganization[]>([]);
    const [highlightedOrganizations, setHighlightedOrganizations] = useState<PartnerOrganization[]>([]);
    const [dispatchPartnerIds, setDispatchPartnerIds] = useState<number[]>();
    const [isShowModalConfirmDispatch, setShowModalConfirmDispatch] = useState(false);
    const FORM_ID = "form-create-partner-dispatches";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<ModalCreatePartnerDispatchesRecipientType>();

    useEffect(() => {
        setDispatchForm({[ModalCreatePartnerDispatchesFormField.RECIPIENT_TYPE]: ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST});
        setSelectedPartners([]);
        setSelectedOrganizations([]);
        setShowErrors(false);
        if (active) {
            (async () => {
                try {
                    setLoading(true);
                    const partners = await session.getPartners();
                    setPartners(partners);

                    let organizations = await session.restPartnerOrganization.list();
                    organizations = sortItems(organizations, PartnerOrganizationField.NAME);
                    setOrganizations(organizations);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("partners", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [active, alert, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST]: (dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST && !selectedOrganizations.length),
            [ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST]: (dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST && !selectedPartners.length)
        }));
    }, [setErrors, dispatchForm.recipientType, selectedOrganizations, selectedPartners]);

    const handleChange = (field: ModalCreatePartnerDispatchesFormField, value: any) => {
        setDispatchForm((prevState) => ({...prevState, [field]: value}));
        if (field === ModalCreatePartnerDispatchesFormField.RECIPIENT_TYPE) {
            setShowErrors(false);
        }
    };

    const handleAddOrganizationType = (organizationType: string) => {
        let typeOrganizations = organizations.filter((it) => it.type === organizationType);
        if (typeOrganizations) {
            const mapSelectedOrganizations = new Map<number, PartnerOrganization>(selectedOrganizations.map((it) => [it.id, it]));
            const addedOrganizations: PartnerOrganization[] = [];
            typeOrganizations.forEach((it) => {
                if (!mapSelectedOrganizations.get(it.id)) {
                    mapSelectedOrganizations.set(it.id, it);
                    addedOrganizations.push(it);
                }
            });
            const newSelectedOrganizations = Array.from(mapSelectedOrganizations, ([key, value]) => ({key, value})).map((it) => it.value);
            setSelectedOrganizations(newSelectedOrganizations);
            setHighlightedOrganizations(addedOrganizations);
            setTimeout(() => setHighlightedOrganizations([]), 1000);
        }
    };

    const handleAddOrganizations = (organizations: PartnerOrganization[]) => {
        setSelectedOrganizations((prevState) => [...prevState, ...organizations]);
        setHighlightedOrganizations(organizations);
        setTimeout(() => setHighlightedOrganizations([]), 1000);
    };

    const handleAddPartners = (partners: Partner[]) => {
        setSelectedPartners((prevState) => [...prevState, ...partners]);
        setHighlightedPartners(partners);
        setTimeout(() => setHighlightedPartners([]), 1000);
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleActiveDispatch = (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        let recipientIds: number[] = [];
        if (dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST) {
            const organizationIds = selectedOrganizations.map((it) => it.id);
            const organizationPartners = partners.filter((it) => organizationIds.find((organizationId) => organizationId === it.organization_id));
            recipientIds = organizationPartners.map((it) => it.id);
        } else if (dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST) {
            recipientIds = selectedPartners.map((it) => it.id);
        }
        setDispatchPartnerIds(recipientIds);
        setShowModalConfirmDispatch(true);
    };

    const handleSubmit = async () => {
        if (dispatchPartnerIds === undefined) {
            return;
        }
        try {
            setLoadingDispatch(true);
            const notificationDispatch = new NotificationDispatch({
                [NotificationDispatchField.IDS]: dispatchPartnerIds,
                [NotificationDispatchField.DISPATCH_DATE]: Formatter.convertDateToUTC(dispatchForm.dispatchDate)
            });

            if (dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ALL) {
                await session.restNotification.update(new Notification({
                    ...notification,
                    [NotificationField.EXPIRES_AT]: notification.expires_at ? Formatter.convertDateToUTC(notification.expires_at) : undefined,
                    [NotificationField.IS_BROADCAST]: true
                }));
                await session.restNotification.dispatch(notification.id, notificationDispatch);
            } else {
                await session.restNotification.addPartners(notification.id, notificationDispatch);
            }
            alert.createWithSuccess("dispatches");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("dispatches", e.message);
            }
        } finally {
            setDispatchPartnerIds(undefined);
            setLoadingDispatch(false);
            setShowModalConfirmDispatch(false);
        }
    };

    return (
        <>
            <ModalNew onClose={() => onClose()} active={active}>
                <ModalHeader>
                    <ModalHeaderTitle title={textNotifications("create_dispatches_to_partners")}/>
                </ModalHeader>
                <ModalContent>
                    <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleActiveDispatch} validationType={FormValidationType.CUSTOM}>
                        <FormLayoutRows>
                            <Alert text={textNotifications("message.recipients_mention")} severity={AlertSeverity.WARNING}/>
                            <FormLayoutColumns columns={3}>
                                <FieldBlock
                                    label={textNotifications("field.dispatch_date")}
                                    tooltip={textNotifications("tooltip.dispatch_date")}
                                    required
                                >
                                    <InputDate
                                        type={InputDateType.DATETIME_LOCAL}
                                        value={dispatchForm.dispatchDate}
                                        onChange={(value) => handleChange(ModalCreatePartnerDispatchesFormField.DISPATCH_DATE, value)}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                            <FieldBlock label={textNotifications("field.recipients")}>
                                <RadioButtons
                                    id={ModalCreatePartnerDispatchesFormField.RECIPIENT_TYPE}
                                    value={dispatchForm.recipientType}
                                    options={[
                                        {value: ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST, label: textNotifications(`partners.${ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST}`)},
                                        {value: ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST, label: textNotifications(`partners.${ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST}`)},
                                        {value: ModalCreatePartnerDispatchesRecipientType.ALL, label: textNotifications(`partners.${ModalCreatePartnerDispatchesRecipientType.ALL}`)}
                                    ]}
                                    onChange={(value) => handleChange(ModalCreatePartnerDispatchesFormField.RECIPIENT_TYPE, value)}
                                />
                            </FieldBlock>
                            {dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST &&
                                <Box>
                                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                            <Select
                                                value=""
                                                placeholder={textNotifications("actions.select_organization_type")}
                                                options={PartnerOrganizationType.values().map((it) => ({value: it.name, label: textOrganizations(`type.${it.name}`)}))}
                                                onChange={(option) => handleAddOrganizationType(option?.value as string)}
                                            />
                                            <SearchItems
                                                items={organizations}
                                                selectedItems={selectedOrganizations}
                                                searchField={PartnerOrganizationField.NAME}
                                                onSubmit={handleAddOrganizations}
                                            />
                                            {formValidator.isError(ModalCreatePartnerDispatchesRecipientType.ORGANIZATION_LIST) &&
                                                <FormLayoutMessage message={t("message.error.element_required", {element: "organization"})} severity={AlertSeverity.DANGER} small/>
                                            }
                                        </FormLayoutRows>
                                        <FieldBlock
                                            label={textNotifications("organizations.selected", {count: selectedOrganizations.length})}
                                            actions={!!selectedOrganizations.length &&
                                                <ButtonLink onClick={() => setSelectedOrganizations([])}>
                                                    {textCommon(Action.REMOVE_ALL.labelKey)}
                                                </ButtonLink>
                                            }
                                        >
                                            <ElementList placeholder={textNotifications("organizations.no_selected")} size={ElementListSize.BIG}>
                                                {selectedOrganizations.map((item) =>
                                                    <TagOrganizationRow
                                                        key={item.id}
                                                        organization={item}
                                                        isHighlighted={highlightedOrganizations.some(({id}) => item.id === id)}
                                                        onRemove={() => setSelectedOrganizations((prevState) => prevState.filter((it) => it.id !== item.id))}
                                                    />
                                                )}
                                            </ElementList>
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            }
                            {dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST &&
                                <Box>
                                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                                            <SearchItems
                                                items={partners}
                                                selectedItems={selectedPartners}
                                                searchField="nameWithCompany"
                                                onSubmit={handleAddPartners}
                                                loading={isLoading}
                                            />
                                            {formValidator.isError(ModalCreatePartnerDispatchesRecipientType.PARTNER_LIST) &&
                                                <FormLayoutMessage message={t("message.error.element_required", {element: "partner"})} severity={AlertSeverity.DANGER} small/>
                                            }
                                        </FieldBlock>
                                        <FieldBlock
                                            label={textNotifications("partners.selected", {count: selectedPartners.length})}
                                            actions={!!selectedPartners.length &&
                                                <ButtonLink onClick={() => setSelectedPartners([])}>
                                                    {textCommon(Action.REMOVE_ALL.labelKey)}
                                                </ButtonLink>
                                            }
                                        >
                                            <ElementList placeholder={textNotifications("partners.no_selected")} size={ElementListSize.BIG}>
                                                {selectedPartners.map((partner) =>
                                                    <TagPartnerRow
                                                        key={partner.id}
                                                        partner={partner}
                                                        isHighlighted={highlightedPartners.some(({id}) => partner.id === id)}
                                                        onRemove={() => setSelectedPartners(selectedPartners.filter(({id}) => partner.id !== id))}
                                                    />
                                                )}
                                            </ElementList>
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            }
                        </FormLayoutRows>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <Button form={FORM_ID} size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} loading={isLoadingDispatch}>
                        {textCommon(Action.SEND.labelKey)}
                    </Button>
                </ModalActions>
            </ModalNew>
            <ModalNew active={isShowModalConfirmDispatch}>
                <ModalContent>
                    <FormLayoutRows>
                        {dispatchForm.recipientType === ModalCreatePartnerDispatchesRecipientType.ALL ?
                            <Alert
                                severity={AlertSeverity.WARNING}
                                text={textNotifications("message.create_summary_all", {
                                    dispatchDate: Formatter.formatDate(dispatchForm.dispatchDate, Formatter.DATETIME_FORMAT)
                                })}
                            /> :
                            <Alert
                                severity={AlertSeverity.WARNING}
                                text={textNotifications("message.create_summary_specific", {
                                    dispatchDate: Formatter.formatDate(dispatchForm.dispatchDate, Formatter.DATETIME_FORMAT),
                                    count: dispatchPartnerIds?.length
                                })}
                            />
                        }
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textNotifications("message.create_summary_confirm")}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                onClick={() => setDispatchPartnerIds(undefined)}
                            >
                                {textCommon(Action.CANCEL.labelKey)}
                            </Button>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.DEFAULT_MIDNIGHT}
                                onClick={handleSubmit}
                            >
                                {textNotifications("actions.create_dispatch")}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </>
    );
};

export default ModalCreatePartnerDispatches;
