import {Country} from "../../common/api/model/Country";
import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {Partner} from "../model/partner/Partner";
import {PartnerProperties} from "../model/partner/PartnerProperties";

export class RestPartner extends Rest implements RestService<Partner> {
    rootPath = "/console-api/partner";

    search(params: any): Promise<SearchResult<Partner>> {
        return new Promise<SearchResult<Partner>>(() => {}); // TODO Implement route
    }

    list(): Promise<Partner[]> {
        return this._client.get(`${this.rootPath}?predicate=id&order=desc`, Partner) as Promise<Partner[]>;
    }

    create(partner: Partner): Promise<Partner> {
        return this._client.post(`${this.rootPath}`, partner, Partner) as Promise<Partner>;
    }

    get(partnerId: number): Promise<Partner> {
        return this._client.get(`${this.rootPath}/${partnerId}`, Partner) as Promise<Partner>;
    }

    update(partner: Partner): Promise<Partner> {
        return this._client.put(`${this.rootPath}/${partner.id}`, partner, Partner) as Promise<Partner>;
    }

    updateProperties(partnerId: number, properties: PartnerProperties): Promise<Partner> {
        return this._client.patch(`${this.rootPath}/${partnerId}/properties`, properties, Partner) as Promise<Partner>;
    }

    updateAllowsSirdataAccess(partner: Partner) {
        return this._client.post(`${this.rootPath}/${partner.id}/allows-sirdata-access?allows_sirdata_access=${partner.allows_sirdata_access}`);
    }

    delete(partnerId: number) {
        return this._client.delete(`${this.rootPath}/${partnerId}`);
    }

    getCountries(): Promise<Country[]> {
        return this._client.get(`${this.rootPath}/country/2`, Country) as Promise<Country[]>;
    }

    getCmpPremiums(): Promise<number[]> {
        return this._client.get(`${this.rootPath}/cmp-premium`) as Promise<number[]>;
    }
}
