import {Action, ButtonLink, ButtonLinkCancel, ButtonValidate, ElementList, ElementListSize, FieldBlock, FlexContentSpacing, Form, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, TranslationLibFile} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {Partner} from "../../../api/model/partner/Partner";
import {PartnerAccount} from "../../../api/model/partner/PartnerAccount";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SearchItems, TagPartnerRow} from "../../snippet";

type ModalEditOrganizationPartnersProps = {
    active: boolean;
    organization: PartnerOrganization;
    partnerAccounts: PartnerAccount[];
    onClose: (refresh: boolean) => void;
};

const ModalEditOrganizationPartners: FunctionComponent<ModalEditOrganizationPartnersProps> = ({active, organization, partnerAccounts, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(false);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
    const [highlightedPartners, setHighlightedPartners] = useState<Partner[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-edit-organization-partners";

    useEffect(() => {
        if (active) {
            (async () => {
                try {
                    setLoading(true);
                    const partners = await session.getPartners();
                    setPartners(partners);

                    const selectedPartnerIds = new Set(partnerAccounts.map((account) => account.partner?.id));
                    setSelectedPartners(partners.filter((partner) => selectedPartnerIds.has(partner.id)));
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("partners", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [partnerAccounts, active, alert]);

    const handleAddPartners = (partners: Partner[]) => {
        setSelectedPartners((prevState) => [...prevState, ...partners]);
        setHighlightedPartners(partners);
        setTimeout(() => setHighlightedPartners([]), 1000);
    };

    const handleRemovePartner = (partner: Partner) => {
        setSelectedPartners(selectedPartners.filter((it) => it.id !== partner.id));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const partnersToRemove = partnerAccounts.filter((account) => !selectedPartners.some((it) => it.id === account.partner?.id)).map((it) => it.partner);
            const partnerIdsToRemove = new Set(partnersToRemove.map((it) => it?.id));
            for (let partnerId of Array.from(partnerIdsToRemove.values())) {
                if (partnerId) {
                    await session.restPartnerOrganization.removePartner(organization, partnerId);
                }
            }

            const partnersToAdd = selectedPartners.filter((partner) => !partnerAccounts.some((it) => it.partner?.id === partner.id));
            for (const partner of partnersToAdd) {
                await session.restPartnerOrganization.addPartner(organization.id, partner.id);
            }
            alert.updateWithSuccess("partners");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("partners", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textOrganizations("modal.edit_partners.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                        <SearchItems
                            items={partners}
                            selectedItems={selectedPartners}
                            searchField="nameWithCompany"
                            onSubmit={handleAddPartners}
                            loading={isLoading}
                        />
                        <FieldBlock
                            label={textOrganizations("partners.selected", {count: selectedPartners.length})}
                            actions={!!selectedPartners.length ?
                                <ButtonLink onClick={() => setSelectedPartners([])}>
                                    {textCommon(Action.REMOVE_ALL.labelKey)}
                                </ButtonLink> : []
                            }
                        >
                            <ElementList placeholder={textOrganizations("partners.list_placeholder")} size={ElementListSize.BIG}>
                                {selectedPartners.map((partner) =>
                                    <TagPartnerRow
                                        key={partner.id}
                                        partner={partner}
                                        isHighlighted={highlightedPartners.some(({id}) => partner.id === id)}
                                        onRemove={() => handleRemovePartner(partner)}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditOrganizationPartners;
