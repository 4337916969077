export enum PartnerField {
    ACCOUNT_ACTIVE = "account_active",
    ACTIVE = "active",
    ALLOWS_SIRDATA_ACCESS = "allows_sirdata_access",
    AXCIOM_ID = "axciom_id",
    BAN_PROPERTY = "ban_property",
    BRANDED = "branded",
    CATEGORIZE_REQUEST = "categorize_request",
    COMPANY = "company",
    CONSENT_STATUS = "consent_status",
    COUNTRY = "country",
    COUNTRY_ID = "country_id",
    CREATION_DATE = "creation_date",
    CREATION_SERVICE = "creation_service",
    CURRENCY_ID = "currency_id",
    DATA = "data",
    EMAIL = "email",
    FIRST_NAME = "first_name",
    FORBID_USER_CREATION = "forbid_user_creation",
    ID = "id",
    IS_BRAZIL_MERCHANT = "is_brazil_merchant",
    IS_MERCHANT = "is_merchant",
    LAST_NAME = "last_name",
    LAST_UPDATE = "last_update",
    MAX_SYNC_PER_REQUEST = "max_sync_per_request",
    OWNER_ACCOUNT_ID = "owner_account_id",
    PRIVACY_POLICY_URL = "privacy_policy_url",
    PROPERTIES = "properties",
    TYPE = "type",
    USER = "user",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at"
}
