import {Action, Alert, AlertSeverity, Box, BoxProps, ContentBlock, ContentBlockAction, EditableLink, EditableLinkValue, FieldBlock, FlexContentDirection, FlexContentSpacing, Form, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, FormLayoutTitle, FormValidationType, InputDate, InputDateType, InputText, InputUrl, LayoutColumns, LayoutRows, Loadable, Select, Table, TableColumnStyle, Tabs, Textarea} from "@sirdata/ui-lib";
import {FormEvent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {SearchResult} from "../../api/interface/SearchResult";
import {Authorization} from "../../api/model/account/Authorization";
import {ApiService} from "../../api/model/ApiService";
import {Media} from "../../api/model/media/Media";
import {MediaType} from "../../api/model/media/MediaType";
import {Notification} from "../../api/model/notifications/Notification";
import {NotificationContent} from "../../api/model/notifications/NotificationContent";
import {NotificationContentField} from "../../api/model/notifications/NotificationContentField";
import {NotificationField} from "../../api/model/notifications/NotificationField";
import {NotificationItem} from "../../api/model/notifications/NotificationItem";
import {NotificationItemField} from "../../api/model/notifications/NotificationItemField";
import {NOTIFICATION_TYPES, NotificationType} from "../../api/model/notifications/NotificationType";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {FormLayoutMessage, MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {TranslationCommonFile} from "../../common/utils/constants";
import {Formatter} from "../../common/utils/Formatter";
import {Locale} from "../../common/utils/Locale";
import {detectChanges} from "../../common/utils/portal";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalCreatePartnerDispatches from "../../component/modal/notifications/ModalCreatePartnerDispatches";
import ModalCreateUserDispatches from "../../component/modal/notifications/ModalCreateUserDispatches";
import ModalRescheduleDispatches from "../../component/modal/notifications/ModalRescheduleDispatches";
import ModalWarningUnsavedNotification from "../../component/modal/notifications/ModalWarningUnsavedNotification";
import {MainContentHeader, MainContentHeaderAction, MediaPicker, NotificationItemRow, SelectService} from "../../component/snippet";
import {PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useFormValidator from "../../utils/hooks/useFormValidator";
import {Module} from "../../utils/Module";
import {NotificationRecipientType} from "../../utils/notification/NotificationRecipientType";

function NotificationsDetails() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);
    const {id} = useParams() as { id: string };
    const navigate = useNavigate();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isLoadingDistribution, setLoadingDistribution] = useState(false);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);
    const [recipientType, setRecipientType] = useState<NotificationRecipientType>();
    const [isShowModalCreateDispatches, setShowModalCreateDispatches] = useState(false);
    const [isShowModalSendPendingDispatches, setShowModalSendPendingDispatches] = useState(false);
    const [isShowModalWarningUnsavedNotification, setShowModalWarningUnsavedNotification] = useState(false);

    const FORM_ID = "form-edit-notification";
    const [notification, setNotification] = useState<Notification>(new Notification());
    const [initNotification, setInitNotification] = useState<Notification>(new Notification());
    const [userNotificationItems, setUserNotificationItems] = useState<NotificationItem[]>([]);
    const [userNotificationItemSearchResult, setUserNotificationItemSearchResult] = useState<SearchResult<NotificationItem>>(new SearchResult(NotificationItem));
    const [partnerAccountNotificationItems, setPartnerAccountNotificationItems] = useState<NotificationItem[]>([]);
    const [partnerAccountNotificationItemSearchResult, setPartnerAccountNotificationItemSearchResult] = useState<SearchResult<NotificationItem>>(new SearchResult(NotificationItem));
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<NotificationContentField>();

    const TEXT_MAX_LENGTH = 150;

    const loadNotification = useCallback(async () => {
        setLoading(true);
        try {
            const notification = await session.restNotification.get(+id);
            const newNotification = new Notification({
                ...notification,
                [NotificationField.EXPIRES_AT]: notification.expires_at ? Formatter.convertUTCToDate(notification.expires_at).format(Formatter.API_DATETIME_FORMAT) : undefined
            });
            setNotification(newNotification);
            setInitNotification(new Notification(newNotification));

            const recipientType = notification.isInternal() ? NotificationRecipientType.USER : NotificationRecipientType.PARTNER;
            setRecipientType(recipientType);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                } else {
                    alert.failToLoad("notification", e.message);
                }
            }
        } finally {
            setLoading(false);
        }
    }, [id, alert]);

    const loadDistributions = useCallback(async () => {
        if (!notification.isDispatched()) return;
        setLoadingDistribution(true);

        try {
            switch (recipientType) {
                case NotificationRecipientType.USER:
                    const newUserNotificationItems = await session.restNotification.getUserItems(notification.id);
                    setUserNotificationItems(newUserNotificationItems);
                    setUserNotificationItemSearchResult(SearchResult.buildFromList(NotificationItem, newUserNotificationItems, 0, PAGE_SIZE));
                    break;
                case NotificationRecipientType.PARTNER:
                    const newPartnerAccountNotificationItems = await session.restNotification.getPartnerAccountItems(notification.id);
                    setPartnerAccountNotificationItems(newPartnerAccountNotificationItems);
                    setPartnerAccountNotificationItemSearchResult(SearchResult.buildFromList(NotificationItem, newPartnerAccountNotificationItems, 0, PAGE_SIZE));
                    break;
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("dispatches", e.message);
            }
        } finally {
            setLoadingDistribution(false);
        }
    }, [notification, recipientType, alert]);

    useEffect(() => {
        (async () => {
            await loadNotification();
        })();
    }, [loadNotification]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(notification, initNotification));
    }, [notification, initNotification]);

    useEffect(() => {
        (async () => {
            await loadDistributions();
        })();
    }, [recipientType, loadDistributions]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [NotificationContentField.IMAGE_ID]:
            (notification.type === NotificationType.MODAL || notification.type === NotificationType.SLIDER) &&
            (!notification.locales.get(Locale.ENGLISH)?.image_id || !notification.locales.get(Locale.FRENCH)?.image_id)
        }));
    }, [setErrors, notification]);

    const handleChange = (field: NotificationField, value: any) => {
        setNotification((prevState) => new Notification({...prevState, [field]: value}));
    };

    const handleChangeType = (type: NotificationType) => {
        const newLocales = new Map(notification.locales);
        if (type === NotificationType.MODAL) {
            Locale.values().forEach((locale) => {
                newLocales.set(locale, new NotificationContent({
                    ...newLocales.get(locale),
                    [NotificationContentField.CALL_TO_ACTION]: textNotifications(`default.call_to_action.${locale}`)
                }));
            });
        }
        handleChange(NotificationField.LOCALES, newLocales);
        handleChange(NotificationField.TYPE, type);
    };

    const handleChangeService = (service: string) => {
        handleChange(NotificationField.SERVICE, service);
        setRecipientType(service === ApiService.CONSOLE.name ? NotificationRecipientType.USER : NotificationRecipientType.PARTNER);
    };

    const handleChangeLocale = (locale: Locale, field: NotificationContentField, value: any) => {
        const newLocales = new Map(notification.locales);
        const localeContent = new NotificationContent({...notification.locales.get(locale), [field]: value});
        newLocales.set(locale, localeContent);
        handleChange(NotificationField.LOCALES, newLocales);
    };

    const handleChangeImage = (locale: Locale, media?: Media) => {
        const newLocales = new Map(notification.locales);
        const localeContent = new NotificationContent(newLocales.get(locale));
        localeContent.image_id = media?.id;
        newLocales.set(locale, localeContent);
        handleChange(NotificationField.LOCALES, newLocales);
    };

    const handleChangeLinkCallToAction = (locale: Locale, value: EditableLinkValue) => {
        const newLocales = new Map(notification.locales);
        const localeContent = new NotificationContent({
            ...notification.locales.get(locale),
            [NotificationContentField.URL]: value.url,
            [NotificationContentField.CALL_TO_ACTION]: value.text
        });
        newLocales.set(locale, localeContent);
        handleChange(NotificationField.LOCALES, newLocales);
    };

    const handleSaveCapture = () => {
        setShowErrors(true);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        try {
            const newNotification = new Notification({
                ...notification,
                [NotificationField.EXPIRES_AT]: notification.expires_at ? Formatter.convertDateToUTC(notification.expires_at) : undefined,
                [NotificationField.CLEAR_EXPIRES_AT]: !notification.expires_at
            });
            await session.restNotification.update(newNotification);
            setNotification(notification);
            setInitNotification(new Notification(notification));
            alert.updateWithSuccess("notification");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("notification", e.message);
            }
        } finally {
            setShowErrors(false);
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restNotification.delete(notification.id);
            navigate(Module.NOTIFICATIONS.path);
            alert.deleteWithSuccess("notification");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("notification", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleCreateDispatches = () => {
        if (isUnsavedChanges) {
            setShowModalWarningUnsavedNotification(true);
        } else {
            setShowModalCreateDispatches(true);
        }
    };

    const handleRefreshNotification = async () => {
        try {
            await loadNotification();
            await loadDistributions();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("notification", e.message);
            }
        }
    };

    const handleRemoveUserDispatch = async (userId: number) => {
        try {
            await session.restNotification.removeUserItem(notification.id, userId);
            await loadDistributions();
            alert.deleteWithSuccess("dispatch");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("dispatch", e.message);
            }
        }
    };

    const handleRemovePartnerAccountDispatch = async (partnerAccountId: number) => {
        try {
            await session.restNotification.removePartnerAccountItem(notification.id, partnerAccountId);
            await loadDistributions();
            alert.deleteWithSuccess("dispatch");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("dispatch", e.message);
            }
        }
    };

    const handleChangePage = (page: number) => {
        if (notification.isInternal()) {
            setUserNotificationItemSearchResult(SearchResult.buildFromList(NotificationItem, userNotificationItems, page - 1, PAGE_SIZE));
        } else {
            setPartnerAccountNotificationItemSearchResult(SearchResult.buildFromList(NotificationItem, partnerAccountNotificationItems, page - 1, PAGE_SIZE));
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.NOTIFICATIONS} element={initNotification.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.NOTIFICATIONS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmitCapture={handleSaveCapture} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textNotifications("section.information")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FieldBlock label={textNotifications(`field.${NotificationField.NAME}`)} required>
                                                    <InputText
                                                        value={notification.name}
                                                        onChange={(value) => handleChange(NotificationField.NAME, value)}
                                                    />
                                                </FieldBlock>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textNotifications(`field.${NotificationField.SERVICE}`)}>
                                                        <SelectService
                                                            value={notification.service}
                                                            services={ApiService.portals()}
                                                            onChange={(service) => handleChangeService(`${service?.name || ""}`)}
                                                            disabled={notification.isDispatched()}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textNotifications(`field.${NotificationField.TYPE}`)}>
                                                        <Select
                                                            value={notification.type}
                                                            options={NOTIFICATION_TYPES.map((it) => ({value: it, label: textNotifications(`type.${it}`)}))}
                                                            onChange={(option) => handleChangeType(option?.value as NotificationType)}
                                                            disabled={notification.isDispatched()}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns columns={2}>
                                                    <FieldBlock label={textNotifications(`field.${NotificationField.EXPIRES_AT}`)} required>
                                                        <InputDate
                                                            type={InputDateType.DATETIME_LOCAL}
                                                            value={notification.expires_at}
                                                            onChange={(value) => handleChange(NotificationField.EXPIRES_AT, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutSeparator/>
                                                <FormLayoutTitle>{textNotifications("section.content")}</FormLayoutTitle>
                                                <Alert text={textNotifications("message.content_mention")}/>
                                                <Tabs headers={Locale.values().map((locale) => ({label: textMain(`language.${locale}`)}))}>
                                                    {Locale.values().map((locale) =>
                                                        <FormLayoutRows key={`${locale}`} spacing={FlexContentSpacing.MEDIUM}>
                                                            {[NotificationType.MODAL, NotificationType.SLIDER].includes(notification.type) &&
                                                                <>
                                                                    <Alert text={textNotifications("message.image_mention")}/>
                                                                    <FieldBlock
                                                                        label={textNotifications(`field.content.${NotificationContentField.IMAGE_ID}`)}
                                                                        content={{direction: FlexContentDirection.COLUMN}}
                                                                        required
                                                                    >
                                                                        <MediaPicker
                                                                            allowedType={MediaType.IMAGE}
                                                                            mediaId={notification.locales.get(locale)?.image_id}
                                                                            onSelect={(media) => handleChangeImage(locale, media)}
                                                                            onRemove={() => handleChangeImage(locale)}
                                                                        />
                                                                        {(!notification.locales.get(locale)?.image_id && formValidator.isError(NotificationContentField.IMAGE_ID)) &&
                                                                            <FormLayoutMessage message={t("message.error.field_required")} severity={AlertSeverity.DANGER} small/>
                                                                        }
                                                                    </FieldBlock>
                                                                    <FormLayoutColumns>
                                                                        <FieldBlock label={textNotifications(`field.content.${NotificationContentField.TITLE}`)}>
                                                                            <InputText
                                                                                value={notification.locales.get(locale)?.title}
                                                                                onChange={(value) => handleChangeLocale(locale, NotificationContentField.TITLE, value)}
                                                                            />
                                                                        </FieldBlock>
                                                                        <FieldBlock label={textNotifications(`field.content.${NotificationContentField.SUBTITLE}`)}>
                                                                            <InputText
                                                                                value={notification.locales.get(locale)?.subtitle}
                                                                                onChange={(value) => handleChangeLocale(locale, NotificationContentField.SUBTITLE, value)}
                                                                            />
                                                                        </FieldBlock>
                                                                    </FormLayoutColumns>
                                                                </>
                                                            }
                                                            <FieldBlock
                                                                label={textNotifications(`field.content.${NotificationContentField.TEXT}`)}
                                                                required={notification.type === NotificationType.DEFAULT}
                                                            >
                                                                <Textarea
                                                                    value={notification.locales.get(locale)?.text}
                                                                    rows={3}
                                                                    maxLength={notification.type !== NotificationType.MODAL ? TEXT_MAX_LENGTH : undefined}
                                                                    onChange={(value) => handleChangeLocale(locale, NotificationContentField.TEXT, value)}
                                                                />
                                                            </FieldBlock>
                                                            <FormLayoutColumns>
                                                                <FieldBlock label={textNotifications(`field.content.${NotificationContentField.URL}`)}>
                                                                    {notification.type === NotificationType.MODAL ?
                                                                        <EditableLink
                                                                            url={notification.locales.get(locale)?.url || ""}
                                                                            text={notification.locales.get(locale)?.call_to_action || textNotifications(`default.call_to_action.${locale}`)}
                                                                            onChange={(value) => handleChangeLinkCallToAction(locale, value)}
                                                                        /> :
                                                                        <InputUrl
                                                                            value={notification.locales.get(locale)?.url}
                                                                            onChange={(value) => handleChangeLocale(locale, NotificationContentField.URL, value)}
                                                                        />
                                                                    }
                                                                </FieldBlock>
                                                            </FormLayoutColumns>
                                                        </FormLayoutRows>
                                                    )}
                                                </Tabs>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                            <Loadable loading={isLoadingDistribution}>
                                {notification.isInternal() ?
                                    <ContentBlock
                                        header={{
                                            title: {label: textNotifications("section.distribution")}, actions: [
                                                <RestrictedContent key="actions" allowedTo={Authorization.NOTIFICATIONS.update}>
                                                    {!!userNotificationItems.filter(({isPending}) => isPending).length &&
                                                        <ContentBlockAction
                                                            action={new Action(textNotifications("actions.reschedule", {pendingCount: userNotificationItems.filter(({isPending}) => isPending).length}), {name: "update"})}
                                                            onClick={() => setShowModalSendPendingDispatches(true)}
                                                        />
                                                    }
                                                    {!notification.is_broadcast &&
                                                        <ContentBlockAction
                                                            action={Action.ADD}
                                                            onClick={handleCreateDispatches}
                                                        />
                                                    }
                                                </RestrictedContent>
                                            ]
                                        }}
                                    >
                                        {notification.is_broadcast &&
                                            <Alert text={textNotifications("message.broadcast_user")} severity={AlertSeverity.INFO}/>
                                        }
                                        <Table
                                            columns={[
                                                {width: 10},
                                                {width: 60, label: textNotifications(`field.${NotificationItemField.DISPATCH_DATE}`)},
                                                {width: 25, label: textNotifications(`field.${NotificationItemField.USER_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                                {width: 5}
                                            ]}
                                            pagination={userNotificationItemSearchResult.getPagination(handleChangePage)}
                                        >
                                            {userNotificationItemSearchResult.elements.map((item) =>
                                                <NotificationItemRow key={item.id} item={item} onDelete={() => handleRemoveUserDispatch(item.user_id!)}/>
                                            )}
                                        </Table>
                                    </ContentBlock> :
                                    <ContentBlock
                                        header={{
                                            title: {label: textNotifications("section.distribution")}, actions: [
                                                <RestrictedContent key="actions" allowedTo={Authorization.NOTIFICATIONS.update}>
                                                    {!!partnerAccountNotificationItems.filter(({isPending}) => isPending).length &&
                                                        <ContentBlockAction
                                                            action={new Action(textNotifications("actions.reschedule", {pendingCount: partnerAccountNotificationItems.filter(({isPending}) => isPending).length}), {name: "update"})}
                                                            onClick={() => setShowModalSendPendingDispatches(true)}
                                                        />
                                                    }
                                                    {!notification.is_broadcast &&
                                                        <ContentBlockAction action={Action.ADD} onClick={handleCreateDispatches}/>
                                                    }
                                                </RestrictedContent>
                                            ]
                                        }}
                                    >
                                        {notification.is_broadcast &&
                                            <Alert text={textNotifications("message.broadcast_partner")} severity={AlertSeverity.INFO}/>
                                        }
                                        <Table
                                            columns={[
                                                {width: 10},
                                                {width: 35, label: textNotifications(`field.${NotificationItemField.DISPATCH_DATE}`)},
                                                {width: 25, label: textNotifications(`field.${NotificationItemField.PARTNER_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                                {width: 25, label: textNotifications(`field.${NotificationItemField.PARTNER_ACCOUNT_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                                {width: 5}
                                            ]}
                                            pagination={partnerAccountNotificationItemSearchResult.getPagination(handleChangePage)}
                                        >
                                            {partnerAccountNotificationItemSearchResult.elements.map((item) =>
                                                <NotificationItemRow key={item.id} item={item} onDelete={() => handleRemovePartnerAccountDispatch(item.partner_account_id!)}/>
                                            )}
                                        </Table>
                                    </ContentBlock>
                                }
                            </Loadable>
                        </LayoutColumns>
                    </LayoutRows>
                </Form>
                {recipientType === NotificationRecipientType.PARTNER ?
                    <ModalCreatePartnerDispatches
                        active={isShowModalCreateDispatches}
                        notification={notification}
                        onClose={(refresh) => {
                            setShowModalCreateDispatches(false);
                            refresh && handleRefreshNotification();
                        }}
                    /> :
                    <ModalCreateUserDispatches
                        active={isShowModalCreateDispatches}
                        notification={notification}
                        onClose={(refresh) => {
                            setShowModalCreateDispatches(false);
                            refresh && handleRefreshNotification();
                        }}
                    />
                }
                <ModalRescheduleDispatches
                    active={isShowModalSendPendingDispatches}
                    notification={notification}
                    onClose={(refresh) => {
                        setShowModalSendPendingDispatches(false);
                        refresh && loadDistributions();
                    }}
                />
                <ModalWarningUnsavedNotification
                    active={isShowModalWarningUnsavedNotification}
                    onClose={() => setShowModalWarningUnsavedNotification(false)}
                />
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="notification"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default NotificationsDetails;
