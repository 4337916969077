import {Action, Button, ButtonSize, ButtonStyle, FieldBlock, Form, FormLayoutRows, FormValidationType, InputDate, InputDateType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, TranslationLibFile} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Notification} from "../../../api/model/notifications/Notification";
import {NotificationDispatch} from "../../../api/model/notifications/NotificationDispatch";
import {NotificationDispatchField} from "../../../api/model/notifications/NotificationDispatchField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {Formatter} from "../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalRescheduleDispatchesProps = {
    active: boolean;
    notification: Notification;
    onClose: (refresh?: boolean) => void;
};

const ModalRescheduleDispatches: FunctionComponent<ModalRescheduleDispatchesProps> = ({active, notification, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);
    const [notificationDispatch, setNotificationDispatch] = useState<NotificationDispatch>(new NotificationDispatch());
    const [isLoading, setLoading] = useState(false);
    const FORM_ID = "form-reschedule-dispatches";

    useEffect(() => {
        if (active) {
            setNotificationDispatch(new NotificationDispatch());
        }
    }, [active]);

    const handleChange = (field: NotificationDispatchField, value: any) => {
        setNotificationDispatch((prevState) => new NotificationDispatch({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const newNotificationDispatch = new NotificationDispatch({
                [NotificationDispatchField.DISPATCH_DATE]: Formatter.convertDateToUTC(notificationDispatch.dispatch_date)
            });
            await session.restNotification.dispatch(notification.id, newNotificationDispatch);
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("reschedule dispatches", e.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose()} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textNotifications("reschedule_dispatches")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock
                            label={textNotifications("field.dispatch_date")}
                            tooltip={textNotifications("tooltip.dispatch_date_pending")}
                            required
                        >
                            <InputDate
                                type={InputDateType.DATETIME_LOCAL}
                                value={notificationDispatch.dispatch_date}
                                onChange={(value) => handleChange(NotificationDispatchField.DISPATCH_DATE, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <Button form={FORM_ID} size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} loading={isLoading}>
                    {textCommon(Action.SEND.labelKey)}
                </Button>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalRescheduleDispatches;
