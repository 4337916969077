import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {MediaCreateRequest} from "../../../api/model/media/MediaCreateRequest";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {
    AlertSeverity,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    Form,
    FormLayoutRows,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    SvgUpload,
    UploadPanel
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import {slugify} from "../../../common/utils/string";
import useAlert from "../../../utils/hooks/useAlert";
import {MediaType} from "../../../api/model/media/MediaType";
import {FormLayoutMessage} from "../../../common/component/snippet";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {MediaCreateRequestField} from "../../../api/model/media/MediaCreateRequestField";
import {UIEventManager} from "../../../common/utils/UIEventManager";

const ModalCreateMedia: FunctionComponent = () => {
    const alert = useAlert();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textMedias} = useTranslation(TranslationPortalFile.MEDIAS);
    const [active, setActive] = useState(false);
    const [onClose, setOnClose] = useState<(refresh: boolean) => void>(() => {});
    const [isLoading, setLoading] = useState(false);
    const [mediaCreateRequest, setMediaCreateRequest] = useState<MediaCreateRequest>(new MediaCreateRequest());
    const FORM_ID = "form-create-media";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<MediaCreateRequestField>();

    useEffect(() => {
        UIEventManager.addListener(ModalCreateMediaUiEvent, (onClose: () => void) => {
            setActive(true);
            setOnClose(() => onClose);
        });
        return () => UIEventManager.removeAllListeners(ModalCreateMediaUiEvent);
    }, []);

    useEffect(() => {
        if (active) {
            setMediaCreateRequest(new MediaCreateRequest());
            setShowErrors(false);
        }
    }, [active, setShowErrors]);

    const handleBrowseFile = (file?: File) => {
        if (!file) return;

        const mediaType = MediaType.values().find(({acceptTypes}) =>
            acceptTypes.some((type) => new RegExp(type).test(file.type) || file.name.endsWith(type))
        );
        if (!mediaType) return;

        setMediaCreateRequest((prevState) => new MediaCreateRequest({
            ...prevState,
            [MediaCreateRequestField.FILE]: file,
            [MediaCreateRequestField.NAME]: `${Date.now()}_${slugify(file.name)}`,
            [MediaCreateRequestField.TYPE]: mediaType.name,
            [MediaCreateRequestField.MIME_TYPE]: file.type
        }));
    };

    useEffect(() => {
        setErrors((prevState) => ({...prevState, [MediaCreateRequestField.FILE]: !mediaCreateRequest.file}));
    }, [setErrors, mediaCreateRequest]);

    const handleChange = (field: MediaCreateRequestField, value: any) => {
        setMediaCreateRequest((prevState) => new MediaCreateRequest({...prevState, [field]: value}));
    };

    const handleClose = (refresh: boolean) => {
        setActive(false);
        onClose(refresh);
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        const formDataRequest = mediaCreateRequest.getFormData();
        if (!formDataRequest) return;
        setLoading(true);

        try {
            await session.restMedia.create(formDataRequest);
            handleClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    alert.failToCreate("media", e.message);
                }
            }
        } finally {
            setLoading(false);
            setShowErrors(false);
        }
    };

    return (
        <ModalNew onClose={() => handleClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textMedias("add_media")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}} required>
                            <UploadPanel
                                fileName={mediaCreateRequest.file?.name || ""}
                                placeholder={textMedias("placeholder.file")}
                                onChange={handleBrowseFile}
                                image={SvgUpload}
                                onRemove={() => setMediaCreateRequest((prevState) => new MediaCreateRequest({type: prevState.type}))}
                                acceptType={MediaType.values().reduce((acc: string[], type) => [...acc, ...type.acceptTypes], [])}
                            />
                            {formValidator.isError(MediaCreateRequestField.FILE) &&
                                <FormLayoutMessage message={t("message.error.file_required")} severity={AlertSeverity.DANGER} small/>
                            }
                        </FieldBlock>
                        {!!mediaCreateRequest.file &&
                            <>
                                <FieldBlock label={textMedias(`field.${MediaCreateRequestField.TITLE}`)} required>
                                    <InputText
                                        value={mediaCreateRequest.title}
                                        onChange={(value) => handleChange(MediaCreateRequestField.TITLE, value)}
                                    />
                                </FieldBlock>
                                <FieldBlock label={textMedias(`field.${MediaCreateRequestField.NAME}`)} required>
                                    <InputText
                                        value={mediaCreateRequest.name}
                                        onChange={(value) => handleChange(MediaCreateRequestField.NAME, slugify(value))}
                                    />
                                </FieldBlock>
                            </>
                        }
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => handleClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isLoading}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateMedia;
export const ModalCreateMediaUiEvent = "ModalCreateMedia";
